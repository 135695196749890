// Sidebar Light

.app-sidebar {
  &.sidebar-text-light {
    border-right: 0 !important;

    .app-sidebar__heading {
      // color: rgba(255, 255, 255, .6);
      color: rgba(255, 255, 255, 1);

      &::before {
        // background: rgba(255, 255, 255, 0.2) !important;
        background: rgba(58, 196, 125, 0.4) !important;
      }
    }

    .metismenu {
      .metismenu-container {
        .metismenu-link {
          // color: rgba(255, 255, 255, .7);
          color: rgba(255, 255, 255, 1);

          &:hover {
            // color: rgba(255, 255, 255, 1);
            // background: rgba(255, 255, 255, .1);
            background: rgba(58, 196, 125, .4);
          }

          &.active {
            // background: rgba(255, 255, 255, .1);
            background: rgba(58, 196, 125, .8);
          }
        }

        &.metismenu-container {
          &.visible {
            .metismenu-item > .metismenu-link {
              // color: rgba(255, 255, 255, .6);
              color: rgba(255, 255, 255, 1);

              &.active {
                // color: rgba(255, 255, 255, .8);
                // background: rgba(255, 255, 255, .1);
                background: rgba(58, 196, 125, .8);
              }

              &:hover {
                color: rgba(255, 255, 255, 1);
              }

            }

            &::before {
              // background: rgba(255, 255, 255, .3);
              background: rgba(255, 255, 255, .8);
            }
          }
        }

        i.metismenu-state-icon,
        i.metismenu-icon {
          opacity: .5;
        }
      }
    }

    .ps__thumb-y {
      background: rgba(58, 196, 125, .3);
    }

    .ps__rail-y:hover {
      .ps__thumb-y {
        background: rgba(58, 196, 125, .2);
      }
    }

    .app-header__logo {
      // .logo-src {
      //   background-image: url('~assets/utils/images/logo-inverse_bak.png');
      // }

      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: rgba(58, 196, 125, .8);
      }
    }
  }
}