.color-note{

    color: #184d83;
    font-weight: 500;
}

 .icon-wrapper-alt{
    background: rgba(255, 255, 255, 0.1) !important;
    transition: all .2s;
    opacity: 1;
}

.btn-blue-x2{
    background: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
    color: white!important;
}

.table-application {
/* border-top: 5px #ff6900 solid!important; */
border-top: 5px #000 solid!important;

}

.progress {
    width: 100%;
}
.metismenu-icon {
    opacity: 1!important;
}
.cursor {
   cursor: pointer!important; 
}
.pe-7s-rotate {
    transform: rotate(180deg); 
    transition: transform 150ms ease;
}
.red {
    color: red;
    margin-right: 8px;
    margin-top: 5px;
    display: inline-block;
}

 .error {
    border: 1px solid red!important;
}
.app-red {
    color: red;
}

.a-blue {
 color: blue!important;
 cursor: pointer!important;
}

.errorClass {
    border: 1px solid red!important;
}

a {
    cursor: pointer!important;
    text-decoration: dotted;
}

iframe {
    border:  none;
}

.underline:hover {
    text-decoration: underline!important;
}
.pe-7s-flag {
    color: #ccc;
}
.active-red {
    color: red; 
    font-weight: 600;
}

.icon-font-size-2{
    font-size: 22px!important;
}


/* login */
.auth-wrapper {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    /* background: #4680ff; */
}
.auth-content {
    position: relative;
    padding: 15px;
    z-index: 5;
}
.auth-wrapper .card {
    margin-bottom: 0;
    padding: 8px;
}
.align-items-center {
    -webkit-align-items: center!important;
    align-items: center!important;
}
.auth-wrapper .auth-content:not(.container) .card-body {
    padding-top: 20px;
    padding-bottom: 30%;
}
.auth-content input:focus
{
    border: 1px solid #1670BE;
    box-shadow: 0 0 3px #1670BE;
    outline-offset: 0px;
    outline: none;
}

.img-fluid { width: 300px;}

.auth-content .card-body {
    border: 1px dashed #dbb914;
}

.form-display {
    display: block;
    width: 100%;
}

/* .vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link{
  color:#211e1e!important
} */

.header-view {
    background-color: #ffd96f;
    padding: 10px;
}
.title-logo-left, .title-logo-right{
    font-size: 27px;
    font-weight: 700;
}
.title-logo-left{
    color: #0d4c84;
    margin-right: 6px;
}
.title-logo-right{
    color: #fbb519;
}
.avartar {
    width: 38px;
    float: left;
    margin-right: 10px;
}
.progress{
    height: 1.5rem;
}
.app-header-right{
    margin-left: 30%!important;
}
.text-lowcapper {
    text-transform:lowercase!important;
}
.not-hover:hover{
    color: unset!important;
}
.header-view {
    display: inline-block;
}
.margin-unset {
    margin: 0px!important;
}

.vertical-align {
    vertical-align:inherit!important;
}
.font-weight {
    font-weight: 600;
}
.color-3f6ad8 {
    color: #3f6ad8;
}

.comment-content{
  
    border-radius: 6px;
    padding: 10px;
}

.user-commnet-active{
  background:  #cccccc30;
}
.user-commnet-notactive{
    background: #5982da30;
  }
.margin-5{
    margin-left: 3%;
}
.margin-1{
    margin-left: 10px;
}
.avatar-icon-comment {
    display: inline-block;
    float: left;
    margin-right: 5px;
    width: 39px;
    height: 39px;
}
.title-name-comment {
    padding-top: 10px;
}

.list-file-comment {
    float: left;
    margin-right: 10px;
    max-width: 200px;
}
.img-file-15 {
    width: 15px;
    height: 18px!important;
}
.text-transform-inherit {
    text-transform:inherit;
    margin-right: 5px;
}

 /* Input tags */

 .react-tags__search-input {
     width: 100%!important;
     height: 50px;
     border: 1px solid #ccc;
 }
 .bule {
     color: blue!important;
 }

 .react-tags__suggestions{
    background: rgb(238, 234, 234)!important;
    opacity: 1;
    border-radius: 10px;
    box-shadow: 10px;
    margin-top: 5px;
 }
 .react-tags__suggestions ul li{
     list-style: none;
 }

 .margin-while{
     background: white!important;
     margin: 0px auto;
 }
 .background-while{
    background: white!important;
   
}
.color-file-comment {
    color: #136bc4;
}

.Progress-true-3{
    background: #d8d2d2!important;
}
.table-profile td {
    border: none;
}
.table-profile thead th {
    border: none;
}
.selected-react {
    padding: 10px!important;
}

.selected-react:hover {
    background: #cccccc30;
}

.selected-react i {

    font-size: 13px!important;
    color: rgb(172, 169, 169)
}
.background-thead {
    background: #ffc107;
}

.background-286090{
    background: #286090!important;
}
.padding-grid-view{
    padding: 1px 6px!important;
}

.padding-5x{
    padding: 5px!important;
}

.font-size-16x{
    font-size: 16px!important;
    padding: 10px;
}
.employee-btn .btn {
    border-radius: 0px!important;
}

.employee-btn .active{
    background-color: #204d74;
    border-color: #204d74;
    color: white;
}
.btn-color-337ab7 {
    background: #337ab7;
    color: white;
}
.btn-color-337ab7:hover{
    color: white;
}
.modal-title {
    width: 100%;
}

.display-inline-block{
    display:inline-block
}
/* b {
    color: #000;
} */

.MuiTable-root tr th,.MuiTable-root tr td
{
    border: 1px solid #ddd;

}
.css-xhtqz8-MuiTableCell-root.TableCell-cellNoWrap,.css-1a6fcoh-MuiTableCell-root.TableTreeCell-cellNoWrap{
    white-space: break-spaces!important;
}
table, th, td {
    
    border-collapse: collapse!important;
  }

  .react-loading-skeleton{
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    background-color: var(--base-color);
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .thead-89c8ff {
      background: #89c8ff;
  }

  .active-row {
    background-color: rgb(212 222 232);
  }

  .font-14 {
      font-size: 16px;
      font-weight: 600;
      margin-right: 5px;
  }

  .btn-boder-radius {
      border-radius: 0;
  }
  .margin-bottom-15{
      margin-bottom: 15px;
  }
  .td-org-position {
      width: 20%;
  }
  .td-org-position button{
   margin-bottom: 10px;
}
.lst-li {
    padding: 1rem;
    padding-left: 5px;
    padding-right: 0;
    position: relative;
}
.btn-lst {
    float: right;
    position: absolute;
    right: 10%;
}
.btn-lst-child {
    float: right;
    position: absolute;
    right: -30%;
}
.mr-2-5 {
    margin-right: 3px;
}
.span-right {
    margin-left: 10px;
}

.margin-t-10{
    margin: 15px 0;
}
li {
    list-style: none;
}

.ul-scroll {
    height: 60%;
    overflow: scroll;
    overflow-x: revert;
}

.scrollbar {
    background-color: #F5F5F5;

    height: 60%;
    margin-bottom: 25px;
    margin-left: 22px;
    margin-top: 40px;
    width: 100%;
       overflow: scroll;
}

.force-overflow {
    min-height: 450px;
}

#style-1::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
} 

.tbody-col2 td {
    font-size:  0.8em;
}

.font-8em{
    font-size:  0.8em;
}
.text-red {
    color: red;
}

.tr-boder-non td{
    border: none;

}

.thead-timekepper tr th {
    font-size: 0.8em;
}

.headcol {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    /*only relevant for first row*/
    margin-top: -1px;
    /*compensate for top border*/
  }

  .long {
    background: yellow;
    letter-spacing: 1em;
  }

  .table-timekeeper th {
      font-size: 0.8em;
  }
  .table-timekeeper td {
    font-size: 0.8em;
}

.width-200 {
    width: 200px;
}

/* ------------------- */
.table-scroll {
	position:relative;
	margin:auto;
	overflow:hidden;
    /* max-width:600px; */
}
.table-wrap {
	width:100%;
	overflow:auto;
}
.table-scroll table {
	width:100%;
	margin:auto;
	border-collapse:separate;
	border-spacing:0;
}
.table-scroll th, .table-scroll td {
	padding:5px 10px;
	/* border:1px solid #000; */
	background:#fff;
	white-space:nowrap;
	vertical-align:top;
}
.table-scroll thead, .table-scroll tfoot {
	background:#f9f9f9;
}
.clone {
	position:absolute;
	top:0;
	left:0;
	pointer-events:none;
}
.clone th, .clone td {
	visibility:hidden
}
.clone td, .clone th {
	border-color:transparent
}
.clone tbody th {
	visibility:visible;
	color:red;
}
.fixed-side {
	/* border:1px solid #000; */
	background:#eee;
	visibility:visible;
}
.clone thead, .clone tfoot{background:transparent;}

/* -------------------- */

.link-color-primary {
    color: #1890a3!important;
    font-weight: 600;
}
.table-scroll .metismenu-icon {
    font-weight: 600;
}
.width-full {
    width: 100%;
}

.font-18 {
    font-size: 18px!important;
}

 .rowHasResultTitle{
    width: 14%;
    display: inline-block;
    float: left;
    border: 1px solid #fff;
    background: #337ab7;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;

}
.style-table-timkeeper {
 padding:10px 0;text-align:center
}


.rowHasResult{
    width: 14%;
    height: 150px;
    display: inline-block;
    float: left;
    border: 1px solid rgb(197, 197, 197);
    padding: 5px;
}


.box-timkeeper .box{
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    /* border-top: 3px solid #d2d6de; */
     margin-bottom: 10px; 
    width: 100%;
    /* box-shadow: 0 1px 1px rgb(0 0 0 / 10%); */
}

.box-timkeeper  .box-header {

    color: #444;
    display: block;
    /* padding: 10px; */
    position: relative;
    margin-left: 5px
}


.rowHasNotResult  {
    width: 14%;
    height: 150px;
    display: inline-block;
    float: left;
    border: none;
}

.holiday {
    background: antiquewhite;
    height: 100%;
}

.font-size-12 {
    font-size: 12px;
}
.display-block {
    display: inline-block;
}

.tr-title {
    background: #337ab7;color: white;
}

.css-2613qy-menu {
    display: inline-block;
}

.table-child-time {
    padding: 12px;
    display: inline-block;
}

.table-background-child-time {
    margin-top: 15px;
    /* background: #dae7f4; */
}

.margin-top-20x{
    margin-top: 20px;
}

.input-border, .input-border:focus-visible {

    border: 1px solid #ccc;
}
._loading_overlay_wrapper {
    width: 100%;
}
.button-1xx {
    width: 88px;
}

.width-full{
    width: 100%;
}

.container-changepassword  {
    width: 800px;
    margin: auto;
    margin-top: 10px;
}



.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }
  
  .container{
    padding-top:50px;
    margin: auto;
  }


  .user-dealine-approve {
    border-left: 3px solid #f7b924;
    padding-left: 7px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px ;
    padding-bottom: 7px;
  }
.mr-top-10 {
    margin-top: 10px!important;
}

.padding-left-25x{
    padding-left: 25px;
}
.result-user-dealine {
    background:#cfe5f885;
}
.vertical-align-inherit{
    vertical-align: inherit!important;
}

.font-3em{
    font-size: 3em!important;
}
.color-pe-7s-close{
    color: red;
}
.color-pe-7s-check{
    color: #28cb62;
}
.background-burlywood {
    background: burlywood;
}

.div-change-deadline {
    padding-left: 10%;
    font-size: 11px;
}

.div-change-deadline table {
    background: #f7f7f7cc;
}

.user-support-head {
    /* position: absolute;
    z-index: 9;
    right: 10%;
    top: 12%; */
    color: red;
    margin: auto;
    font-weight: 600;

}
.vertical-align-initial {
    vertical-align: initial!important;
  }

  .bold {
    font-weight: 600;
  }

  .permission-dms-td {
    text-align: center;
    margin-top: 5px;
  }

  .MuiDataGrid-virtualScroller {
    height: auto!important;
  }

  .permission-dms-td {
    text-align: center;
    margin-top: 5px;
  }

  .button-notRadius{
    height: 30px;
    padding: 3px 8px 2px 13px;
    border-radius: 0;
    background: #f9f9f9;
  }
  .color-black {
    color: black;
  }
  .vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link {
    color: #343a40;
  }

  .title-logo {
    width: 100%;
    font-weight: 800;
    color: white;
    font-size: 26px;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    top: -5px;
  }

  .attr-logo{
    position: absolute;
    text-align: center!important;
    bottom: 6px;
    color: white;
    -webkit-text-decoration: solid;
    text-decoration: solid;
    width: 178px;
  }

  .search-icon span{
    position: absolute!important;
    bottom: 9px;
    left: 9px;
  }

  .notification-infor {
    background: #cccccc30;
    padding: 10px;
    border-radius: 10px;
  }
  .ant-notification-notice-message {
    font-weight: 600;
    color: #e7a714;
  }

  .header-dots-mess {
        margin-left: 5px;
  }

  .header-dots-mess .icon-wrapper-alt {
    margin: 0;
    height: 44px;
    width: 44px;
    text-align: center;
    overflow: visible;
}
.header-dots-mess .icon-wrapper-alt svg{
    margin: 0 auto;
}


.header-dots-mess .icon-wrapper-bg{
    opacity: 0.2!important;
}

.green {
    color: #28a745;
}

.notification-timeline-app {
    padding: 5px;
    border: 1px solid #ccc;
    background: #efefef;
    margin-top: 10px;
}

.pull-right {
    float: right;
}
/* .css-imf0nl-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    white-space: inherit!important;
} */

.margin-right-10x {
    margin-left: 2.8%;
}

.hover-report-app {
    color: #463939!important;
}

.hover-report-app:hover {
    cursor: pointer!important;
    text-decoration: underline;
}

.alert-orange {
    color: #000;
    background-color: var(--yellow);
    border-color: var(--yellow);
}

::-webkit-scrollbar {
    display: none;
}

.CardHeader {
    page-break-inside: auto;
}
.card-body {
    page-break-inside: auto;
}

.app-sidebar .app-sidebar__inner {
    padding: 2px 1.0rem 1.5rem!important;
}

.alert-default {
    color: #000;
    background-color: #e7e7e7;
    border-color:  #e7e7e7;
}

