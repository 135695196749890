.mentions {
    margin: 1em 0;
  }
  
  .mentions--singleLine .mentions__control {
    display: inline-block;
  }
  .mentions--singleLine .mentions__higlighter {
    padding: 1px;
    border: 2px inset transparent;
  }
  .mentions--singleLine .mentions__input {
    padding: 5px;
    border: 2px inset;
  }
  
  .mentions--multiLine .mentions__control {
    font-family: monospace;
    font-size: 14pt;
    border: 1px solid silver;
  }
  .mentions--multiLine .mentions__highlighter {
    padding: 9px;
  }
  .mentions--multiLine .mentions__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
  }
  
  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
  }
  
  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .mentions__suggestions__item--focused {
    background-color: #cee4e5;
  }
  
  .mentions__mention {
    background-color: #cee4e5;
  }

  .mentions__control {
    font-size: 12px!important;
  }